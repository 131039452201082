body {
  background-color: #1a1a1a;
  color: #fff;
  font-family: 'Helvetica Neue', Arial, sans-serif;
}

.slideshow {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.title {
  text-align: center;
  font-size: 3em;
  margin-bottom: 30px;
  font-weight: 300;
  letter-spacing: 2px;
}

.content-container {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 10px;
}

.image-wrapper {
  width: 54%;
  max-width: 620px;
  aspect-ratio: 1 / 1;
  /* This ensures the image wrapper maintains a square aspect ratio */
}

.nft-image {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 8px;
  transition: opacity 1s ease-in-out;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  object-fit: cover;
  /* This ensures the image covers the area without distortion */
}

.right-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 44%;
  height: auto;
  /* Changed from 100% to auto */
}

.attributes-container {
  flex-grow: 1;
  background-color: rgba(255, 255, 255, 0.05);
  padding: 20px 30px 30px;
  /* Reduced top padding */
  border-radius: 8px 8px 0 0;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  transition: opacity 0.5s ease-in-out;
  max-height: calc(100% - 80px);
  display: flex;
  flex-direction: column;
}

.controls-container {
  background-color: rgba(255, 255, 255, 0.03);
  padding: 20px;
  border-radius: 0 0 8px 8px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  margin-top: auto;
  /* This pushes the controls to the bottom */
}

.nft-name {
  font-size: 2.8em;
  /* Reduced from 3.6em */
  /* Doubled from 1.8em */
  margin-bottom: 20px;
  /* Reduced from 30px */
  padding-bottom: 20px;
  /* Added padding below the title */
  /* Reduced from 40px */
  /* Increased from 30px for better spacing */
  text-align: center;
  font-weight: 300;
  letter-spacing: 1px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  /* Optional: adds a subtle line below the title */
}

.attribute {
  margin-bottom: 20px;
  /* Increased from 15px for better spacing */
  font-size: 1.8em;
  /* Doubled from 0.9em */
  letter-spacing: 0.5px;
}

.speed-control {
  display: flex;
  align-items: center;
  justify-content: center;
}

.speed-control input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  height: 2px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  transition: opacity .2s;
}

.speed-control input[type="range"]:hover {
  opacity: 1;
}


.controls {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.nav-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 10px;
  transition: all 0.3s ease;
  color: white;
  font-size: 16px;
  font-weight: bold;
}

.nav-button svg {
  width: 24px;
  height: 24px;
  fill: white;
}

.nav-button:hover svg,
.nav-button:hover {
  fill: #4CAF50;
  color: #4CAF50;
}

.play-pause-button svg {
  width: 28px;
  height: 28px;
}

.speed-button {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 50%;
  border: 2px solid white;
}

.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0;
}

.loading {
  text-align: center;
  font-size: 24px;
  margin-top: 50px;
}

/* Mobile styles */
@media (max-width: 768px) {
  .slideshow-container {
    padding: 10px;
  }

  .content-wrapper {
    flex-direction: column;
  }

  .image-container {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .image-container img {
    max-height: 50vh;
    width: auto;
    max-width: 100%;
    object-fit: contain;
  }

  .info-container {
    width: 100%;
  }

  .attributes {
    text-align: center;
  }

  .controls {
    justify-content: center;
  }

  button {
    margin: 0 10px;
  }

  .slideshow {
    padding: 10px;
  }

  .title {
    font-size: 2em;
    margin-bottom: 20px;
  }

  .content-container {
    flex-direction: column;
    gap: 20px;
  }

  .image-wrapper {
    width: 100%;
    max-width: none;
    aspect-ratio: 1 / 1;
  }

  .nft-image {
    width: 100%;
    max-width: 100%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
  }

  .right-container {
    width: 100%;
    height: auto;
    /* Reset height for mobile view */
  }

  .attributes-container {
    max-height: none;
    /* Remove the max-height constraint on mobile */
    padding: 15px 20px 20px;
    /* Adjusted padding for mobile */
    padding-bottom: 0;
    /* Remove bottom padding */
  }

  .nft-name {
    font-size: 2em;
    /* Slightly reduced from 2.2em */
    margin-bottom: 15px;
    /* Reduced from 20px */
    padding-bottom: 15px;
  }

  .attribute,
  .listing-info {
    font-size: 1em;
    /* Reduced from 1.2em */
    margin-bottom: 10px;
    /* Reduced from 15px */
  }

  .controls-container {
    padding: 15px;
    margin-top: 0;
    /* Remove space between containers */
    border-radius: 0;
    /* Remove border radius */
  }

  .controls {
    /* flex-wrap: wrap; */
    gap: 10px;
  }

  .nav-button {
    padding: 8px 16px;
    font-size: 14px;
  }

  .attribute,
  .listing-info p {
    font-size: 1em;
    margin-bottom: 10px;
  }
}

/* Additional adjustments for very small screens */
@media (max-width: 480px) {
  .title {
    font-size: 1.6em;
    /* Slightly reduced from 1.8em */
  }

  .nft-name {
    font-size: 1.8em;
    /* Reduced from 2em */
  }

  .attribute,
  .listing-info p {
    font-size: 0.9em;
    /* Reduced from 1em */
  }

  .nav-button {
    padding: 6px 12px;
    font-size: 12px;
  }
}

.slideshow-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.image-container {
  flex: 1;
  margin-right: 20px;
}

.image-container img {
  width: 100%;
  height: auto;
  max-width: 600px;
  border-radius: 8px;
}

.text-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.nav-button {
  padding: 10px 20px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.nav-button:hover {
  background-color: #0056b3;
}

.nav-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Add responsive styles */
@media (max-width: 768px) {
  .slideshow-container {
    flex-direction: column;
    padding: 10px;
  }

  .image-container {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .image-container img {
    width: 100%;
    height: 100%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    padding: 10px;
    box-sizing: border-box;
  }

  .text-container {
    width: 100%;
  }

  .navigation-buttons {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .nav-button {
    padding: 8px 16px;
    font-size: 14px;
  }
}

.image-wrapper,
.attributes-container {
  transition: opacity 0.5s ease-in-out;
}

.image-wrapper.transitioning,
.attributes-container.transitioning {
  opacity: 0;
}

.nft-image {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 8px;
  transition: opacity 1s ease-in-out;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  object-fit: cover;
  /* This ensures the image covers the area without distortion */
}

.price {
  margin-left: 10px;
  font-weight: bold;
  color: #bb3adc;
  /* or any color that fits your design */
}

/* ... (rest of the styles remain the same) */